// UTILITY FUNCTIONS FOR USER

import type { LocationDocument, UserDocument, WorkspaceDocument, Address } from "@onpreo/database";

export const getSignatureByUser = (user: Pick<UserDocument, "workspace" | "location" | "gender" | "meta" | "email">, location?: LocationDocument) => {
    console.log("getSignatureByUser", user, location);
    const workspace = user?.workspace as WorkspaceDocument;
    const locationDoc = location ?? (user?.location as LocationDocument);
    console.log("getSignatureByUser - workspace", workspace);
    console.log("getSignatureByUser - locationDoc", locationDoc);
    // NOTE: maybe workspace.publication should be considered here as well
    // NOTE: maybe  should be considered here as well

    // @ts-ignore (taken from a previous version)
    const workspacePhone = workspace?.companyInfo?.phone ?? workspace?.companyInfo?.mobile;

    // taken from email logic in components/ email / templates
    console.log(workspace?.extras?.use?.forEmail, workspace?.extras?.logo?.src);
    const logo =
        workspace?.extras?.use?.forEmail && workspace?.extras?.logo?.src !== undefined
            ? workspace?.extras?.logo
            : { src: "https://onpreo-stage-images.s3.eu-central-1.amazonaws.com/default/logo.png" };

    console.log("getSignatureByUser - return value", {
        image: user?.meta?.img ?? workspace?.agentImage,
        logo,
        name: user?.meta?.name,
        phone: user?.meta?.phone ?? locationDoc?.meta?.phone ?? workspacePhone, // location?.meta?.phone ? location.meta.phone : user.meta?.phone,
        jobRole: user?.meta?.jobRole,
        email: user?.email ?? locationDoc?.meta?.email,
        location: locationDoc?.name,
        company: workspace?.companyInfo?.company ?? workspace?.billing?.company ?? " ",
        website: workspace?.companyInfo?.website ?? " ",
        address: locationDoc ? locationDoc?.meta?.address : workspace?.companyInfo?.address,
        gender: user?.gender,
        customHTML: " "
    });

    return {
        image: user?.meta?.img ?? workspace?.agentImage,
        logo,
        name: user?.meta?.name,
        phone: user?.meta?.phone ?? locationDoc?.meta?.phone ?? workspacePhone, // location?.meta?.phone ? location.meta.phone : user.meta?.phone,
        jobRole: user?.meta?.jobRole,
        email: user?.email ?? locationDoc?.meta?.email,
        location: locationDoc?.name,
        company: workspace?.companyInfo?.company ?? workspace?.billing?.company ?? " ",
        website: workspace?.companyInfo?.website ?? " ",
        address: locationDoc ? locationDoc?.meta?.address : workspace?.companyInfo?.address,
        gender: user?.gender,
        customHTML: " "
    };
};

export const getCustomSignature = (
    user: Pick<UserDocument, "workspace" | "location" | "gender" | "meta" | "email" | "mailConfig">,
    loc?: LocationDocument
) => {
    console.log("getCustomSignature", user, loc);
    const { name, company, phone, email, address, image, logo, jobRole, location, website, gender } = getSignatureByUser(user, loc);

    return {
        image: user?.mailConfig?.signatureImg ?? image,
        logo,
        name: (user?.mailConfig?.signature?.name as string) ?? name,
        phone: (user?.mailConfig?.signature?.phone as string) ?? phone,
        jobRole: (user?.mailConfig?.signature?.jobRole as string) ?? jobRole,
        email: (user?.mailConfig?.signature?.email as string) ?? email,
        location,
        company: (user?.mailConfig?.signature?.company as string) ?? company,
        website: (user?.mailConfig?.signature?.website as string) ?? website,
        address: (user?.mailConfig?.signature?.address as string | Address) ?? address,
        gender,
        customHTML: (user?.mailConfig?.signature?.htmlInput as string) ?? " "
    };
};

export type UserSignature = ReturnType<typeof getSignatureByUser>;
