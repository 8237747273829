import { RealEstateDocument } from "@onpreo/database/src/definitions/real-estate";
import { Range } from "@onpreo/components";
import { RealEstateLandingPage } from "@onpreo/database/src/definitions/real-estate-landing-page";
import { RealEstateChangesDocument } from "@onpreo/database";

export type RealEstateArray = RealEstateDocument[];

export type CurrentRealEstateState = RealEstateDocument & { _id?: string };

export type RealEstateState = {
    realEstateByRange: RealEstateArray;
    type: "loaded" | "loading" | "error";
    total: number;
    totalLandingPages: number;
    activeRange: any;
    activeSort: any;
    activeQuery: {
        addressValues?: { street?: string; town?: string; zip?: string };
        responsibleUser?: any;
        withOpenTasks?: boolean;
        withoutOpenTasks?: boolean;
        tags?: string[];
        start?: string;
        end?: string;
        locations?: string[];
        categories?: string[];
        constructions?: string[];
        steps?: string[];
        lowerPrice?: number | string;
        upperPrice?: number | string;
    };
    tkn: string;
    currentRealEstate: CurrentRealEstateState;
    currentRealEstateLp?: RealEstateLandingPage;
    publishedLP?: RealEstateLandingPage[];
    updateAllWatermarks: boolean;
    realEstatesBySteps: any;
    totalByStep: any;
    loadingByStep: any;
    statisticByStep: any;
    partnerTags: string[];
    realEstateChanges?: RealEstateChangesDocument;
};

export const initialRealEstateState = {
    realEstateByRange: [] as RealEstateArray,
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    totalLandingPages: -1,
    activeRange: undefined as Range | undefined,
    activeSort: null,
    activeQuery: {
        addressValues: null,
        responsibleUser: null,
        withOpenTasks: true,
        withoutOpenTasks: true,
        tags: [],
        start: "",
        end: "",
        locations: undefined,
        categories: undefined,
        constructions: undefined,
        steps: undefined,
        lowerPrice: 0,
        upperPrice: 0
    },
    tkn: null,
    currentRealEstate: null,
    currentRealEstateLp: undefined,
    publishedLP: [] as RealEstateLandingPage[],
    updateAllWatermarks: false,
    realEstatesBySteps: {},
    totalByStep: {},
    loadingByStep: {},
    statisticByStep: {},
    partnerTags: [],
    realEstateChanges: undefined
};
