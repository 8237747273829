const subscriptionLimits = {
    // Price Finder only routes
    accessPipelinesView: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: true,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    accessActivitiesView: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: true,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    accessAutomationCenterView: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: true,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    numTeamUsers: {
        PF: 0,
        BASIC: 3,
        BASIC_23Q4: 3,
        PRO: 5,
        PRO_23Q4: 5,
        ELITE: 50
    }, // check
    numLocations: {
        PF: 1,
        BASIC: 5,
        BASIC_23Q4: 1,
        PRO: 5,
        PRO_23Q4: 5,
        ELITE: 10
    }, // check
    numValuationsPerMonth: {
        PF: 5,
        BASIC: 5,
        BASIC_23Q4: 5,
        PRO: 10,
        PRO_23Q4: 10,
        ELITE: 20
    },
    numAiTokens: {
        // per day
        PF: 0,
        BASIC: 0,
        BASIC_23Q4: 0,
        PRO: 25000,
        PRO_23Q4: 22500,
        ELITE: 35000
    },
    accessExposeGenerator: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: false,
        ELITE: true
    }, // check
    accessRealEstatePortals: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: false,
        ELITE: true
    }, // check
    accessRealEstateLandingPages: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: false,
        ELITE: true
    },
    numRealEstateLandingPages: {
        PF: 0,
        BASIC: 0,
        BASIC_23Q4: 0,
        PRO: 20,
        PRO_23Q4: 0,
        ELITE: 50
    },
    accessBuyerManagement: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: false,
        ELITE: true
    }, // check
    accessAfterSalesProcess: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: false,
        PRO_23Q4: false,
        ELITE: true
    },
    accessBuyerBehaviorProfiles: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: false,
        PRO_23Q4: false,
        ELITE: true
    },
    accessBuyerLeadManagement: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: false,
        PRO_23Q4: false,
        ELITE: true
    },
    accessActivityCenterAutomation: {
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    accessMailCenterAutomation: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    accessPipelineCustomization: {
        PF: false,
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    numCustomPipelines: {
        PF: 0,
        BASIC: 0,
        BASIC_23Q4: 0,
        PRO: 10,
        PRO_23Q4: 10,
        ELITE: 20
    },
    accessMarketingMailCenterAutomation: {
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    },
    accessSalesPipeline: {
        PF: false,
        BASIC: true,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: false,
        ELITE: true
    },
    accessShowReports: {
        BASIC: false,
        BASIC_23Q4: false,
        PRO: true,
        PRO_23Q4: true,
        ELITE: true
    }
};

export type SubscriptionLimit = keyof typeof subscriptionLimits;

export default subscriptionLimits;
